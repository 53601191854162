import { IClient, IClinic } from "./../../interfaces/entity-interfaces";
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { DataService } from "./../../services/data-service";

@autoinject()
export class HistoModal {
    _dialogController: DialogController;
    _dataService: DataService;
    diagnostic: any;

    constructor(dialogController: DialogController, dataService: DataService) {
        this._dialogController = dialogController;
        this._dataService = dataService;
    }

    activate(diagnostic) {
        this.diagnostic = diagnostic;
        if (this.diagnostic.histo) {
            this.histo = this.diagnostic.histo;
            this.markListsSelected();
            this.histoMassDescriptions = diagnostic.histo.mass.description;
            this.histoBiopsyPrimaryLesions =
                diagnostic.histo.biopsy.primaryLesions;
            this.histoBiopsySecondaryLesions =
                diagnostic.histo.biopsy.secondaryLesions;
        }
        console.log("Activate Diagnostic", this.diagnostic);
    }

    histoMassDescriptions: Array<string> = [];
    histoBiopsyPrimaryLesions: Array<string> = [];
    histoBiopsySecondaryLesions: Array<string> = [];

    histo: any = {
        mass: {
            location: "",
            size: "",
            consistency: "",
            attachment: "",
            ageOfMass: "",
        },
        biopsy: {
            primaryLesions: [],
            secondaryLesions: [],
            pruritus: false,
            pruritusDescription: "",
            parasites: false,
            parasitesDescription: "",
        },
    };

    currentHistoType: string = "Masses";

    massConsistencyOptions: any[] = ["Solid", "Cystic"];
    massAttachmentOptions: any[] = ["Freely Movable", "Firmly Attached"];

    primaryLesions: any[] = [
        {
            name: "Macule",
            isActive: false,
        },
        {
            name: "Patch",
            isActive: false,
        },
        {
            name: "Papule",
            isActive: false,
        },
        {
            name: "Plaque",
            isActive: false,
        },
        {
            name: "Nodule",
            isActive: false,
        },
        {
            name: "Vesicule",
            isActive: false,
        },
        {
            name: "Bulla",
            isActive: false,
        },
        {
            name: "Pustule",
            isActive: false,
        },
        {
            name: "Wheal",
            isActive: false,
        },
    ];

    secondaryLesions: any[] = [
        {
            name: "Scale",
            isActive: false,
        },
        {
            name: "Crust",
            isActive: false,
        },
        {
            name: "Epidermal Collarette",
            isActive: false,
        },
        {
            name: "Ulcer",
            isActive: false,
        },
        {
            name: "Erosion",
            isActive: false,
        },
        {
            name: "Excoriation",
            isActive: false,
        },
        {
            name: "Scar",
            isActive: false,
        },
        {
            name: "Callus",
            isActive: false,
        },
        {
            name: "Comedone",
            isActive: false,
        },
        {
            name: "Cyst",
            isActive: false,
        },
        {
            name: "Abscess",
            isActive: false,
        },
        {
            name: "Erythema",
            isActive: false,
        },
        {
            name: "Hypopigmentation",
            isActive: false,
        },
        {
            name: "Hyperpigmentation",
            isActive: false,
        },
        {
            name: "Alopecia (symmetric or asymmetric)",
            isActive: false,
        },
    ];

    selectMassConsistencyOption(option) {
        this.histo.mass.consistency = option;
    }
    selectMassAttachmentOption(option) {
        this.histo.mass.attachment = option;
    }

    selectMassDescription(massOption) {
        if (massOption.isSelected == true) {
            this.histoMassDescriptions = this.histoMassDescriptions.filter(
                (d) => d !== massOption.name
            );
            massOption.isSelected = false;
        } else {
            this.histoMassDescriptions.push(massOption.name);
            massOption.isSelected = true;
        }
    }
    selectPrimaryLesion(primaryLesion) {
        if (primaryLesion.isSelected == true) {
            this.histoBiopsyPrimaryLesions =
                this.histoBiopsyPrimaryLesions.filter(
                    (d) => d !== primaryLesion.name
                );
            primaryLesion.isSelected = false;
            console.log(this.histoBiopsyPrimaryLesions);
        } else {
            this.histoBiopsyPrimaryLesions.push(primaryLesion.name);
            primaryLesion.isSelected = true;
            console.log(this.histoBiopsyPrimaryLesions);
        }
    }
    selectSecondaryLesion(secondaryLesion) {
        if (secondaryLesion.isSelected == true) {
            this.histoBiopsySecondaryLesions =
                this.histoBiopsySecondaryLesions.filter(
                    (d) => d !== secondaryLesion.name
                );
            secondaryLesion.isSelected = false;
            console.log(this.histoBiopsySecondaryLesions);
        } else {
            this.histoBiopsySecondaryLesions.push(secondaryLesion.name);
            secondaryLesion.isSelected = true;
            console.log(this.histoBiopsySecondaryLesions);
        }
    }

    close() {
        this.diagnostic.histo = this.histo;
        this.diagnostic.histo.mass.description = this.histoMassDescriptions;
        this.diagnostic.histo.biopsy.primaryLesions =
            this.histoBiopsyPrimaryLesions;
        this.diagnostic.histo.biopsy.secondaryLesions =
            this.histoBiopsySecondaryLesions;
        this._dialogController.cancel();
    }

    markListsSelected() {
        if (this.histo.biopsy.primaryLesions.length > 0) {
            this.histo.biopsy.primaryLesions.forEach((lesion) => {
                this.primaryLesions.find((d) => d.name == lesion).isSelected =
                    true;
            });
        }
        if (this.histo.biopsy.secondaryLesions.length > 0) {
            this.histo.biopsy.secondaryLesions.forEach((lesion) => {
                this.secondaryLesions.find((d) => d.name == lesion).isSelected =
                    true;
            });
        }
    }
}
