import { DialogController } from "aurelia-dialog";
import { ICourierRouteSheet } from "interfaces/entity-interfaces";
import { autoinject, observable } from "aurelia-framework";
import FlatPickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import * as moment from 'moment';
import { computedFrom } from "aurelia-binding";

@autoinject()
export class AddCourierRouteSheetRecordModal {

	_dialogController: DialogController;

	flatPickerObject: FlatPickr.Instance;

	currentDashboardDate: Date;

	constructor(dialogController: DialogController) {
		this._dialogController = dialogController;
		this.currentDashboardDate = moment().toDate();

	}

	selectedClientName: string = "";
	selectedClientId: number;

	@observable({ changeHandler: "searchUpdated" })
	searchTerm: string = "";

	selectedCourierRouteShortId: string;

	courierRouteSheets: Array<ICourierRouteSheet> = [];

	clients = [];

	matchingClients = [];

	activate(modelData) {
		console.log(modelData);

		this.courierRouteSheets = modelData.courierRoutes;
		this.clients = modelData.clients;

		console.log(this.clients);

	}

	attached() {
		const datepickerElement = document.getElementById("courier-dashboard-datepicker");

		this.flatPickerObject = FlatPickr(datepickerElement, {
			altInput: true,
			defaultDate: this.currentDashboardDate,
			altFormat: "F j, Y",
			minDate: "today",
			onChange: (newDate) => {
				console.log(newDate);
				this.currentDashboardDate = newDate[0];
			}
		});
	}

	searchUpdated() {
		if (this.clients == null)
			return;

		const lowerSearchTerm = this.searchTerm.toLowerCase();

		this.matchingClients = this.clients.filter(client => {
			if (client.name == null)
				return false;

			return client.name.toLowerCase().includes(lowerSearchTerm);
		});
	}

	selectClient(client) {
		console.log(client);

		this.selectedClientId = client.clientId;
		this.selectedClientName = client.name;

		this.searchTerm = "";
	}

	async addNewCourierRecord() {
		const returnedData = await fetch(`/CourierRouteSheets/CreateNewAccessionRecord?clientId=${this.selectedClientId}&shortRouteId=${this.selectedCourierRouteShortId}&testClinicName=${this.selectedClientName}`, {
			method: "POST",
			headers: {

				'Content-Type': 'application/json',
				'Accept': 'application/json',
				//'Authorization': `Bearer ${this._authenticationService.session.token}`

			}
		});

		this._dialogController.close(true);
	}

	@computedFrom('currentDashboardDate')
	get formattedCurrentDashboardDate(): string {
		return moment(this.currentDashboardDate).format("MMMM D, YYYY")

	}

	closeDialogBox() {
		this._dialogController.close(false);
	}

	toggleDatePicker() {
		this.flatPickerObject.toggle();
		return false;
	}
}