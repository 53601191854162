import { DialogController } from "aurelia-dialog";
import { autoinject, observable, computedFrom } from "aurelia-framework";

@autoinject()
export class IntegrationModal {
    _dialogController: DialogController;

    constructor(dialogController: DialogController) {
        this._dialogController = dialogController;
    }
    clinic: any;

    ezyVetClientId: string;
    ezyVetClinetSecret: string;

    activate(clinic) {
        this.clinic = clinic;
        console.log(this.clinic);
    }

    closeDialogBox() {
        this._dialogController.close(false);
    }

    copyToClipboard(text) {
        navigator.clipboard.writeText(text);
    }

    hasIntegrationPartner(clinic: any) {
        return clinic.clients.some(
            (client) => client.integrationPartner != null
        );
    }

    save() {
        //save the stuff
        this.closeDialogBox();
    }
}
