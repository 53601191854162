import { DialogController } from "aurelia-dialog";
import { autoinject, observable, computedFrom } from "aurelia-framework";
import { IPatient } from "interfaces/entity-interfaces";

@autoinject()
export class NewPatientModal {

	_dialogController: DialogController;

	constructor(dialogController: DialogController) {
		this._dialogController = dialogController;
	}

	availableSexes = [
		{ id: "FS", description: "Female - Spayed" },
		{ id: "MN", description: "Male - Neutered" },
		{ id: "F", description: "Female - Unspayed" },
		{ id: "M", description: "Male - Unneutered" }
	]


	@observable({ changeHandler: "searchUpdated" })
	searchTerm: string = "";

	patientFirstName: string = ""
	ownerFirstName: string = ""
	ownerLastName: string = ""
	speciesAndBreeds: any = null
	patientSex: { id: string, description: string } = null
	selectedSpecies: any;
	selectedBreed: any = null;

	activate(modelData) {
		console.log(modelData);

		this.speciesAndBreeds = modelData.speciesAndBreeds;

		console.log(this.speciesAndBreeds.species);

	}

	@computedFrom('selectedSpecies', 'speciesAndBreeds')
	get availableBreeds() {
		if (this.selectedSpecies == null)
			return [];

		console.log(this.selectedSpecies);

		var breeds = this.speciesAndBreeds.breeds.filter(b => b.species == this.selectedSpecies.id);

		console.log(breeds);

		return breeds;
	}


	async addNewPatient() {

		const randomNumber = Math.floor(Math.random() * 100000000) + 1;


		var patient: IPatient = {
			patientFirstName: this.patientFirstName,
			patientLastName: this.ownerLastName,
			patientSpecies: this.selectedSpecies.id,
			patientBreed: this.selectedBreed.id,
			patientNumber: randomNumber.toString(),
			ownerFirstName: this.ownerFirstName,
			ownerLastName: this.ownerLastName,
			species: this.selectedSpecies.id,
			breed: this.selectedBreed.breed,
			sex: this.patientSex.id.includes("F") ? "F" : "M",
			age: "",
			neutered: this.patientSex.id.includes("N") ? "Y" : "N"
		}


		this._dialogController.close(true, patient);
	}

	closeDialogBox() {
		this._dialogController.close(false);
	}
}