import { IClient, IClinic } from "./../../interfaces/entity-interfaces";
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { DataService } from "./../../services/data-service";

@autoinject()
export class UrinalysisModal {
    _dialogController: DialogController;
    _dataService: DataService;
    diagnostic: any;

    constructor(dialogController: DialogController, dataService: DataService) {
        this._dialogController = dialogController;
        this._dataService = dataService;
    }
    showErrorMessage: boolean = false;
    options: Array<string> = ["Cysto", "Catheter", "Free Catch"];

    activate(diagnostic) {
        this.diagnostic = diagnostic;
    }

    selectOption(option) {
        this.diagnostic.sampleCollectionType = option;
    }

    close() {
        this._dialogController.cancel();
    }

    confirmSelection() {
        if (
            this.diagnostic.sampleCollectionType == "Cysto" ||
            this.diagnostic.sampleCollectionType == "Catheter" ||
            this.diagnostic.sampleCollectionType == "Free Catch"
        ) {
            this.close();
        } else {
            this.showErrorMessage = true;
        }
    }
}
