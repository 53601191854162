import { IClient, IClinic } from "./../../interfaces/entity-interfaces";
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { DataService } from "./../../services/data-service";

@autoinject()
export class ClinicModal {
    _dialogController: DialogController;
    _dataService: DataService;

    isEditing: boolean = false;
    creatingNewUser: boolean = false;

    newUserEmail: string = "";
    clientBeingEdited: IClient;

    constructor(dialogController: DialogController, dataService: DataService) {
        this._dialogController = dialogController;
        this._dataService = dataService;
    }

    clinic: IClinic;

    activate(modelData) {
        this.clinic = modelData.clinic;
        console.log(this.clinic);
        //give an isEditing field to each client in the clinic.
        this.clinic.clients.forEach((client) => {
            client.isEditing = false;
        });
    }

    close() {
        this._dialogController.cancel();
    }

    editClinic() {
        this.isEditing = !this.isEditing;
    }
    saveClinic() {
        this.isEditing = !this.isEditing;
    }

    async resetClientPassword(client: IClient) {
        var newTempPassword = await this._dataService.resetPasswordAsync({
            userId: client.id,
        });
        console.log(newTempPassword.newPassword);
        client.tempPassword = newTempPassword.newPassword;
    }
    startCreatingNewUser() {
        this.creatingNewUser = true;
    }
    async saveNewUser() {
        var newPasswordResponse = await this._dataService.createClient({
            username: this.newUserEmail,
            clientId: this.clinic.clinicId,
        });
        this.clinic.clients.push({
            id: "",
            name: this.newUserEmail,
            userName: this.newUserEmail,
            tempPassword: newPasswordResponse.newPassword,
            clinicId: this.clinic.clinicId,
            email: [this.newUserEmail],
        });
        this.creatingNewUser = false;
    }
    editClient(client) {
        client.isEditing = true;
        this.clientBeingEdited = structuredClone(client);
    }
    async saveClient(client) {
        //update client
        const returnClient = await this._dataService.updateClient(client);
        console.log(returnClient);
        client.isEditing = false;            
    }
    async dontSaveClient(client) {
        client = this.clientBeingEdited;
        this.clientBeingEdited = null;
    }
}
