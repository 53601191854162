import { DialogController } from "aurelia-dialog";
import { autoinject, observable, computedFrom } from "aurelia-framework";

@autoinject()
export class PasswordResetModal {
    _dialogController: DialogController;

    currentPassword: string = null;
    currentPasswordError: string;

    newPassword: string = null;
    newPasswordError: string;

    confirmPassword: string = null;
    confirmPasswordError: string;

    showValidationErrors: boolean = false;

    constructor(dialogController: DialogController) {
        this._dialogController = dialogController;
    }

    closeDialogBox() {
        this._dialogController.close(false);
    }

    submitPasswordReset() {
        this.validate();
    }

    validate() {
        if (this.currentPassword == null || this.currentPassword == "") {
            this.currentPasswordError = "Enter your current password";
        } else {
            this.currentPasswordError = null;
        }
        if (this.newPassword == null || this.newPassword == "") {
            this.newPasswordError = "Enter your new password";
        } else {
            this.newPasswordError = null;
        }
        if (this.confirmPassword == null || this.confirmPassword == "") {
            this.confirmPasswordError = "Confirm your new password";
        } else if (this.newPassword != this.confirmPassword) {
            this.confirmPasswordError = "Passwords do not match";
        } else {
            this.confirmPasswordError = null;
        }

        if (
            this.currentPasswordError != null ||
            this.newPasswordError != null ||
            this.confirmPasswordError != null
        ) {
            this.showValidationErrors = true;
            return;
        } else {
            this.showValidationErrors = false;
            this._dialogController.ok({
                currentPassword: this.currentPassword,
                newPassword: this.newPassword,
            });
        }
    }

    save() {
        //save the stuff
        this.closeDialogBox();
    }
}
